import React, { Component } from 'react';
import { Row, Col, Divider } from 'antd';
import { LabelSelect, LabelInput, LabelSwitch } from 'libs';

class Light extends Component {
    constructor (props) {
        super(props);
        this.state = {

        };
    }
    // light link enableChange
    handleLightChange (e) {
        let {
            motionData,
            curChannel,
            updateMotionData
        } = this.props;
        motionData[curChannel].EventHandler.LightingLink.Enable = e;
        updateMotionData(motionData);
    }
    // light link valueChange
    handleValueChange (type, val) {
        let {
            motionData,
            curChannel,
            updateMotionData
        } = this.props;
        if (type === 'LightDuration') {
            let lightDuration = val - 0;
            lightDuration = Math.round(lightDuration) > lightDuration ? parseInt(lightDuration) + 0.5 :
                (Math.round(lightDuration) < lightDuration ? parseInt(lightDuration) : lightDuration);
            motionData[curChannel].EventHandler.LightingLink.FilckerIntevalTime = lightDuration * 10;
            updateMotionData(motionData);
        } else {
            let lightNum = val - 0;
            motionData[curChannel].EventHandler.LightingLink.FilckerTimes = lightNum;
            updateMotionData(motionData);
        }
    }
    // light link typeChange
    handleTypeChange (val) {
        let {
            motionData,
            curChannel,
            updateMotionData
        } = this.props;
        motionData[curChannel].EventHandler.LightingLink.FilckerLightType = val;
        updateMotionData(motionData);
    }
    render () {
        let {
            $t,
            motionData,
            curChannel,
            lightDuration,
            lightNum,
            lightTypeSelection,
            publicState: {
                getGrid
            }
        } = this.props;
        let multiple = {
            type: 'normal',
            multiple: 1
        };
        let target = motionData[curChannel];
        //if (capsLightLink && item.EventHandler.LightingLink)  判断条件省略，等用得上再说吧
        return (
            <React.Fragment>
                {/* 灯光相关 */}
                <Row className='formItem'>
                    <Row style={{ marginBottom: '15px' }}>
                        <Col {...getGrid(multiple).container}>
                            <LabelSwitch
                                label={$t('ivs.LightLink')}
                                colWidth={getGrid(multiple).colWidth}
                                checked={target.EventHandler.LightingLink.Enable}
                                onChange={this.handleLightChange.bind(this)}
                            />
                        </Col>
                    </Row>
                    {target.EventHandler.LightingLink.Enable ? <Row style={{ marginBottom: '15px' }} >
                        <Col {...getGrid(multiple).container}>
                            <LabelSelect
                                label={$t('ivs.LightLinkType')}
                                options={lightTypeSelection}
                                value={target.EventHandler.LightingLink.FilckerLightType}
                                onChange={this.handleTypeChange.bind(this)}
                                colWidth={getGrid(multiple).colWidth}
                            />
                        </Col>
                    </Row> : ''}
                    <Row style={{ marginBottom: '15px' }}>
                        <Col {...getGrid(multiple).container}>
                            <LabelInput
                                onChange={this.handleValueChange.bind(this, 'LightDuration')}
                                value={target.EventHandler.LightingLink.FilckerIntevalTime * 0.1}
                                behind={`${$t('com.Second')}` + ' ' + lightDuration.text}
                                min={lightDuration.min} max={lightDuration.max} precision={0}
                                label={$t('ivs.LightLinkTime')}
                                colWidth={getGrid(multiple).colWidth}
                                type='number'
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col {...getGrid(multiple).container}>
                            <LabelInput
                                onChange={this.handleValueChange.bind(this, 'LightNum')}
                                value={target.EventHandler.LightingLink.FilckerTimes}
                                behind={`${$t('com.Rate')}` + ' ' + lightDuration.text}
                                min={lightNum.min} max={lightNum.max} precision={0}
                                label={$t('ivs.LightLinkNum')}
                                colWidth={getGrid(multiple).colWidth}
                                type='number'
                            />
                        </Col>
                    </Row>
                    <Divider />
                </Row>
            </React.Fragment>
        );
    }
}

export default Light;
